<section id="thanks">
  <div id="contactwrap">
    <div class="container">
  
      <div class="row">
        <div class="col-lg-6 col-lg-offset-3">
          <div class="flex-container-contact">
            <h2 style="color:rgb(235, 170, 6)">THANK YOU</h2>
          </div>
          
        <div class="flex-container-contact">
          <div class="item1">
            <a href="#home"><h2 style="color:rgb(235, 170, 6)">Go To Home</h2></a>  
          </div>
        </div>
      </div>
      </div>
      </div>
    </div>
    </section>