<form [formGroup]="form" (ngSubmit)="onSubmit()">
<div id="contactwrap"  style="padding-top: 100px;">
  <div class="container">
    <div class="row">
      
      
      
      <div class="col-lg-6 col-lg-offset-3">
      
       <div class="flex-container-contact">
          <h2 style="color:rgb(235, 170, 6)">Owner Operator</h2>
       </div>
        
   
       <div class="flex-container-contact">
        <div class="item2">
          <input type="text" class="form-control" formControlName="firstName" placeholder="Your Name" data-rule="minlen:4"  data-msg="Please enter at least 4 chars">
        </div>
        <div class="item2">
          <input type="text" class="form-control" formControlName="lastName" placeholder="Your LastName" data-rule="minlen:4" data-msg="Please enter at least 8 chars of lastname">
        </div>
        <div class="item2">
          <input type="text" class="form-control" formControlName="email" placeholder="Your Email" data-rule="email"  data-msg="Please enter a valid email">
        </div>
      </div>
    

      <div class="flex-container-contact">
      <div class="item2">
          <input type="text"  class="form-control" formControlName="phone" placeholder="Your Phone" data-rule="minlen:4"  data-msg="Please enter at least 4 chars">
        </div>
        <div class="item2">
          <input type="text"  class="form-control" formControlName="vechileType" placeholder="Vechile Type" data-rule="minlen:4"  data-msg="Please enter at least 4 chars">
        </div>
      </div>


      <div class="flex-container-contact">
       
        <div class="item2">
          <select formControlName="indcomp" class="form-control">
            <option value="Individual" selected>Individual</option>
            <option value="Company">Company</option>
          </select>
        </div>
        <div class="item2">
          <input type="text"  class="form-control" formControlName="city" placeholder="City Of Residence" data-rule="minlen:4"  data-msg="Please enter at least 4 chars">
        </div>
        <div class="item2">
          <input type="text"  class="form-control" formControlName="state" placeholder="State Of Residence" data-rule="minlen:4"  data-msg="Please enter at least 4 chars">
        </div>
      
      </div>  
     
      <div class="flex-container-contact">
        <div class="item1">
          <textarea class="form-control" formControlName="comment" placeholder="How can we help you today?" rows="5" data-rule="required" data-msg="Please write something for us"></textarea>
        </div>
      </div>
      <div class="form-group">
        <button  [disabled]="loading"  class="btn btn-primary">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Send
        </button>&nbsp;&nbsp;&nbsp;&nbsp;
        <button  class="btn btn-primary" routerLink="/">
          <span  class="spinner-border spinner-border-sm mr-1"></span>
            Cancel
      </button>
    </div> 
    </div>
    </div>
  </div>
  <!-- /container -->
</div>
</form>