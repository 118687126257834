import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Quote } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class QuoteService {

    constructor(private http: HttpClient){}; 

   
    register(quote: Quote) {
      
        return this.http.post(`${environment.apiUrl}/brokers/register`, quote);

    }

}