<!DOCTYPE html>
<html lang="en">
    <head>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="" />
        <meta name="author" content="" />
        <title>Metro Trans Logic</title>
        <!-- Favicon-->
        <link rel="icon" type="image/x-icon" href="assets/img/favicon.ico" />
        <!-- Font Awesome icons (free version)-->
        <script src="https://use.fontawesome.com/releases/v5.13.0/js/all.js" crossorigin="anonymous"></script>
        <!-- Google fonts-->
        <link href="https://fonts.googleapis.com/css?family=Merriweather+Sans:400,700" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css?family=Merriweather:400,300,300italic,400italic,700,700italic" rel="stylesheet" type="text/css" />
        <!-- Third party plugin CSS-->
        <link href="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/magnific-popup.min.css" rel="stylesheet" />

        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css">


        

        <!-- Core theme CSS (includes Bootstrap)-->
    </head>
    <body id="page-top">
        <!-- Navigation-->
        <nav class="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav">
            <div class="container">
                <a class="navbar-brand js-scroll-trigger" href="#page-top">Metro Trans Logistics LLC</a>
                <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav ml-auto my-2 my-lg-0">
                        <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#about">About</a></li>
                        <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#services">Services</a></li>
                      <!--
                        <li class="nav-item">
                          <a routerLink="broker" class="nav-link js-scroll-trigger">Broker</a>
                        </li>
                        <li class="nav-item">
                          <a routerLink="owner" class="nav-link js-scroll-trigger">Owner Operator</a>
                        </li>
                      -->    
                        <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#cont">Contact</a></li>
                    </ul>
                </div>
            </div>
        </nav>
        <!-- Masthead-->
        <header class="masthead">
            <div class="container h-100">
                <div class="row h-100 align-items-center justify-content-center text-center">
                    <div class="col-lg-10 align-self-end">
                        <h1 class="text-uppercase text-white font-weight-bold">We Carry Your Trust</h1>
                        <hr/>
                    </div>
                    <div class="col-lg-8 align-self-baseline">
                        <p class="text-white-75 font-weight-light mb-5">Our dedicated team makes sure that your freight will be managed and tracked 24/7, getting your freight from the origin to the destination without a hitch!</p>
                        <a class="btn btn-primary btn-xl js-scroll-trigger" href="#about">Find Out More</a>
                    </div>
                </div>
            </div>
        </header>
        <!-- About-->
        <section class="page-section bg-primary" id="about">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8 text-center">
                        <h2 class="text-white mt-0">We've got what you need!</h2>
                        <hr/>
                        <p class="text-white-50 mb-4">Metro Trans Logistics is a freight transportation company – offering expedited services to its customers. The story of Metro began back in 2017 with just one single vehicle and since then it has been up and running 24/7/365. Company has developed into one of the best service providers on market – having its fleet complete with hundreds of trucks and professional drivers all over United States. Metro Trans Logistics will continue being a go to partner for every single customer, with a commitment to quality service and dedication to becoming the best transportation provider in the industry.</p>
                        <a class="btn btn-light btn-xl js-scroll-trigger" href="#services">Get Started!</a>
                    </div>
                </div>
            </div>
        </section>
        <!-- Services--> 
        <section class="page-section" id="services">
            <div class="container">
                <h2 class="text-center mt-0">At Your Service</h2>
                <hr/>
                <div class="row">
                    <div class="col-lg-5 col-md-6 text-center">
                        <div class="mt-5">
                          <i class="fa fa-4x fa-globe text-primary mb-4"></i>
                            <h3 class="h4 mb-2">Up to Date</h3>
                            <p class="text-muted mb-0">Every single link of the operations chain is managed by qualified team 24/7/365!</p>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-6 text-center">
                        <div class="mt-5">
                          <i class="fa fa-4x fa-truck text-primary mb-4"></i>
                            <h3 class="h4 mb-2">Ready to Go</h3>
                            <p class="text-muted mb-0">Our team of professionals are ready to haul your load nationwide at any time!</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!---  Broker   -->
        
      
        <!-- Contact-->
        <section class="page-section" id="cont">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8 text-center">
                        <h2 class="mt-0">Let's Get In Touch!</h2>
                        <hr/>
                        <p class="text-muted mb-5">Would you like to speak with the representative? Give us a call or send an email and we will get back to you as soon as possible!</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
                        <i class="fa fa-phone fa-3x mb-3 text-muted"></i>
                        <div style="color:#c12d2d">Phone: (571) 800 -1030</div>
                    </div>
                    <div class="col-lg-4 mr-auto text-center">
                        <i class="fa fa-envelope fa-3x mb-3 text-muted"></i>
                        <!-- Make sure to change the email address in BOTH the anchor text and the link target below!-->
                        <a class="d-block" href="mailto:contact@yourwebsite.com">metrotrans@mtlfreight.com</a>
                    </div>
                </div>
                <div>
                  <br/> <br/>
                 <div class="col-lg-17 mr-auto text-center"> Metro Trans Logistics LLC
                  41 Ethan Drive #1A
                  New Providence, NJ 07974
                </div>
                </div>
               
            </div>
        </section>
        <!-- Footer-->
        <footer class="bg-light py-5">
            <div class="container"><div class="small text-center text-muted">Copyright © 2020 - Metro Trans Logistics LLC</div></div>
        </footer>
        <!-- Bootstrap core JS-->
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.bundle.min.js"></script>
        <!-- Third party plugin JS-->
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.4.1/jquery.easing.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
        <!-- Core theme JS-->
        <script src="js/scripts.js"></script>
    </body>
</html>
