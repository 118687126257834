import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormsModule  } from '@angular/forms';
import { QuoteService } from '@app/_services';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';




@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.css']
})
export class QuoteComponent implements OnInit {
  form!: FormGroup;
  submitted = false;
  loading = false;
  constructor(private formBuilder: FormBuilder, private quoteService: QuoteService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: ['', Validators.required],
      companyName: ['', Validators.required],
      email: ['', Validators.required],
      mcdot: ['', Validators.required],
      comment: ['', Validators.required]
  });
  
  }


  onSubmit() {
    this.submitted = true;

    
    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }

    this.loading = true;
    this.register();
  }




  private register() {
   
    this.quoteService.register(this.form.value).pipe(first()).subscribe(
            data => {
                //this.alertService.success('Driver added successfully', { keepAfterRouteChange: true });
                this.router.navigate(['thanks', { relativeTo: this.route }]);
            },
            error => {
                //this.alertService.error(error);
                window.alert(error);
                this.loading = false;
            });
 }







}
