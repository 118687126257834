import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Rep } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class RepService {

    constructor(private http: HttpClient){}; 

   
    register(rep: Rep) {
        return this.http.post(`${environment.apiUrl}/owneroperators/register`, rep);
    }

}