<section id="quote">
<form [formGroup]="form" (ngSubmit)="onSubmit()">  
<div id="contactwrap" style="padding-top: 100px;">
    <div class="container">
  
      <div class="row">
        <div class="col-lg-6 col-lg-offset-3">
          <div class="flex-container-contact">
            <h2 style="color:rgb(235, 170, 6)">Broker</h2>
          </div>
          
        <div class="flex-container-contact">
        
          <div class="item2">
            <input type="text" class="form-control" formControlName="firstName" placeholder="Your Name" data-rule="minlen:4"  data-msg="Please enter at least 4 chars">
          </div>
        
          <div class="item2">
            <input type="text" class="form-control" formControlName="lastName" placeholder="Your Last Name" data-rule="minlen:4"  data-msg="Please enter at least 4 chars">
          </div>
        
          <div class="item2">
            <input type="text" class="form-control" formControlName="phone" placeholder="Your Phone" data-rule="minlen:4"  data-msg="Please enter at least 4 chars">
          </div>
        
          
        </div>
        
        
        <div class="flex-container-contact">
          <div class="item2">
            <input type="text" class="form-control" formControlName="companyName" placeholder="Your Company Name" data-rule="minlen:4"  data-msg="Please enter at least 4 chars">
          </div>
        
          <div class="item2">
            <input type="text" class="form-control" formControlName="email" placeholder="Your Email" data-rule="minlen:4"  data-msg="Please enter at least 4 chars">
          </div>

          <div class="item2">
            <input type="text" class="form-control" formControlName="mcdot" placeholder="Broker MC# or DOT#" data-rule="name"  data-msg="Please enter a valid MC/DOT">
          </div>
        </div>
        
  
        
        <div class="flex-container-contact">
          <div class="item1">
            <textarea class="form-control" formControlName="comment" placeholder="How can we help you today?" rows="5"
              data-rule="required" data-msg="Please write something for us"></textarea>
          </div>
        </div>
        
        <div class="form-group">
          <button  class="btn btn-primary">
              <span  class="spinner-border spinner-border-sm mr-1"></span>
              Send
          </button>&nbsp;&nbsp;&nbsp;&nbsp;
          <button  class="btn btn-primary" routerLink="/">
            <span  class="spinner-border spinner-border-sm mr-1"></span>
              Cancel
        </button>
      </div> 
      </div>
      </div>
    </div>
    <!-- /container -->
  </div>
  </form>
</section>