import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './contact/contact.component';
import { NavbarComponent } from './navbar/navbar.component';
import { QuoteComponent } from './quote/quote.component';
import { ThanksComponent } from './thanks/thanks.component';



const routes: Routes = [
  { path: '', component: NavbarComponent},
  { path: 'owner', component: ContactComponent },
  { path: 'broker', component: QuoteComponent },
  { path: 'thanks', component: ThanksComponent },
  { path: '**', redirectTo: ''}
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
